import React from 'react';
import { connect } from 'react-redux'
import { loaderOperations } from '../redux/loader/'
import { authOperations } from '../redux/auth/'
import { Alert,Card, Row, Col, Modal, Button, ListGroup,Table, Badge, Accordion,Container,Spinner, Toast } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from 'react-bootstrap';

import MediaElement from './MediaElement'
import EntryInfo from './EntryInfo'

import Entry from './Entry'
import { isURL, checkVideoFormat, isVideoplayable } from './Helpers'

import axios from 'axios'
import variables from '../redux/variables';

class InfoViewer extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        const entry = this.props.entry
        return(
            <Modal
                    show={this.props.show}
                    onHide={this.props.onHide}
                    dialogClassName=""
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                    <Modal.Header closeButton>
                        
                    </Modal.Header>
                    <Modal.Body>
                            <Row>
                                <Col>
                                    <h3>Entry Infomation</h3>
                                    <EntryInfo division={this.props.division} entry={entry}/>
                                </Col>
                            </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e)=>this.props.onHide(e)}>Close</Button>
                    </Modal.Footer>
                    </Modal>
        )

    }

}

class PDFViewer extends React.Component{
    constructor(props){
        super(props)

    
    }

    render(){
        const pdflink = this.props.pdflink
        const entry = this.props.entry
        
        return(
            <Modal
                    show={this.props.show}
                    onHide={this.props.onHide}
                    dialogClassName="modal-95w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                    <Modal.Header closeButton>
                        
                    </Modal.Header>
                    <Modal.Body>
                            <Row>
                                <Col xs={12} md={9}>
                                    <h3>Entry Supporting Material</h3>
                                    <embed src={`${pdflink}`} style={{width:'100%',height:'100vh'}}/>
                                </Col>
                                <Col xs={12} md={3}>
                                    <h3>Entry Infomation</h3>
                                    <EntryInfo division={this.props.division} entry={entry}/>
                                </Col>
                            </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e)=>this.props.onHide(e)}>Close</Button>
                    </Modal.Footer>
                    </Modal>
        )
    }
}

class SpecialAnswers extends React.Component{
    constructor(props){
        super(props)

    }

    returnSpecialQuestionsFromId(question_id){
        var specialquestions = this.props.specialquestions
        for (var i = 0; i < specialquestions.length; i++) {
            if (specialquestions[i].question_id == question_id){
                return specialquestions[i].question_text
            }
          } 
       
        
    }



    render(){
        /*<MediaElement
            id="videoplayer"
            mediaType="video"
            preload="auto"
            controls
            width="320"
            height="240"
            poster=""
            sources={JSON.stringify( [
                {src: `${answer.answer_text}`, type:`${checkVideoFormat(answer.answer_text)}` },
            ])}
            options={JSON.stringify([])}
            tracks={JSON.stringify([])}
        />*/
        
        const entry = this.props.entry
        var answer_list = []
        if (entry.hasOwnProperty('answer_list')){
            answer_list = entry.answer_list
        }
        return(
            <Modal
                    show={this.props.show}
                    onHide={this.props.onHide}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                    >
                   
                    <Modal.Header closeButton>
                        <EntryInfo division={this.props.division} entry={entry}/>
                    </Modal.Header>
                    <Modal.Body>
                    {
                        answer_list ? 
                                (answer_list.map((answer,k)=>
                                    <div key={k}>
                                        <strong>
                                            <div  dangerouslySetInnerHTML={{__html: this.returnSpecialQuestionsFromId(answer.question_id)}}></div>
                                        </strong>
                                        {
                                            ( isURL(answer.answer_text)) ?
                                                (isVideoplayable(answer.answer_text)) ?
                                                <>
                                                    <div>Issues playing Video: Use the link below</div>
                                                    <a target="_blank" href={answer.answer_text}><div  dangerouslySetInnerHTML={{__html: answer.answer_text}}></div> </a>
                                                    <MediaElement
                                                        id="videoplayer"
                                                        mediaType="video"
                                                        preload="auto"
                                                        controls
                                                        width="320"
                                                        height="240"
                                                        poster=""
                                                        sources={JSON.stringify( [
                                                            {src: `${answer.answer_text}`, type:`${checkVideoFormat(answer.answer_text)}` },
                                                        ])}
                                                        options={JSON.stringify([])}
                                                        tracks={JSON.stringify([])}
                                                    />
                                                    <div>Issues playing Video: Use the link below</div>
                                                    <a target="_blank" href={answer.answer_text}><div  dangerouslySetInnerHTML={{__html: answer.answer_text}}></div> </a>
                                                </>
                                             : <a target="_blank" href={answer.answer_text}><div  dangerouslySetInnerHTML={{__html: answer.answer_text}}></div> </a>
                                             : <div  dangerouslySetInnerHTML={{__html: answer.answer_text}}></div>
                                                 
                                        }
                                    </div>)
                                ) : null
                    }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e)=>this.props.onHide(e)}>Close</Button>
                    </Modal.Footer>
                    </Modal>
                   
                
        )
    }
}


class UserEntries extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            panelid: 0,
            category: {category_has_audio:0},
            division: [],
            entries: [],
            oldDivision: '',
            source: [],
            audioplay: '',
            specialquestions:[],
            currentEntry: '',
            filterButton: '',
            modalShowPDF: false,
            modalShowSpecial: false,
            modalShowInfo: false,
            selectedEntry: null,
            selectedDivision: null,
            selectedPDFlink:null,
            categorypdf: '',
            isFetching: false,
            toastShow: false,
            scoreEntry: null,
            
        }
        this.setSourceList = this.setSourceList.bind(this)
        this.pausePlayer = this.pausePlayer.bind(this)
        this.setCurrentEntry = this.setCurrentEntry.bind(this)
        this.setEntryScoreList = this.setEntryScoreList.bind(this)
        this.setModalPDFShow = this.setModalPDFShow.bind(this)
        this.setModalSpecialShow = this.setModalSpecialShow.bind(this)
        this.setModalInfoShow = this.setModalInfoShow.bind(this)
        this.setToast = this.setToast.bind(this)
    
    }

    setModalInfoShow(e,show, division,entry){
        this.setState({modalShowInfo: show, selectedEntry:entry,selectedDivision:division});
    }

    setModalPDFShow(e,show, pdflink, division,entry){
        this.setState({modalShowPDF: show, selectedEntry:entry,selectedDivision:division,selectedPDFlink:pdflink});
    }

    setModalSpecialShow(e,show, specialquestions, division, entry){
        this.setState({modalShowSpecial: show, selectedEntry:entry,selectedDivision:division,specialquestions:specialquestions});
    }

    componentDidMount() {
        //const {panelid, categoryid} = this.props
        //console.log(panelid, categoryid)
        
        this.prefillData()
        
        console.log("I'm looping")
        
    }

    async getData(url, stateName, result){
        var current = this
        await axios.get(variables.apiURL+url, {headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}})
            .then(function(response){
                if (result){
                    current.setState({
                        [stateName]: response.data.results
                    })
                }else{
                    current.setState({
                        [stateName]: response.data
                    })
                    if (stateName == 'category'){
                        current.setState({
                            categorypdf: response.data.pdflink,
                        })
                        
                    }
                }
                //dispatch(receiveData(response))
            })
            .catch(function(error){
                if (error.response){
                    //console.log(error.response.data.non_field_errors[0])
                    //dispatch(DataError(error.response.data.non_field_errors[0]))
                    //dispatch(dataError('Error Logging into server'))  
                }else{
                //dispatch(dataError('Error Logging into server'))  
                }
            });
    }

    setToast(isShow, entry){
        this.setState({toastShow:isShow, scoreEntry:entry})
    }

    prefillData(){
        const panelid = this.props.match.params.panelid
        const categoryid = this.props.match.params.categoryid
        const { dispatch, final } = this.props
        console.log(final)
        this.setState({isFetching:true})
        this.getData(`api/allcategory/${categoryid}/`,'category')
        if (final === true){
            this.getData(`api/entries/?categoryid=${categoryid}&panelid=${panelid}&final=${final}`,'entries',true)
        }else{
            this.getData(`api/entries/?categoryid=${categoryid}&panelid=${panelid}`,'entries',true)
        }
        
        this.getData(`api/division/`,'division',true)
        this.getData(`api/question/?categoryid=${categoryid}`,'specialquestions',true)

        this.setState({
            filterEntries: this.state.entries,
            panelid:panelid,
            categorypdf: this.state.category.pdflink,
        })
        this.setState({isFetching:false})
    }



    async setSourceList(e, audio){
        e.preventDefault();

        var source = [
            {src: `${variables.mediaURL}media/entries/audio/${audio.audio_file}`, type: 'audio/mp3'},
        ]
        
        console.log("Pressed play")
        await this.setState({source:source,audioplay:audio.id})  
        var player = document.getElementById("audioplayer");     
        player.play()

      
    }

    findEntryIndex(entryid){
       
        for (var i = 0; i < this.state.entries.length; i++) {
            if (this.state.entries[i].id === entryid){
                return i
            }
        }

    }

    setEntryScoreList(entryid, score_data){
        const entry_index = this.findEntryIndex(entryid)
        const clone = this.state.entries.slice(0);
        console.log(entry_index)
        var entry = clone[entry_index]
        entry.score_list = score_data

        clone.splice(entry_index,1,entry)

        this.setState({entries:clone})



    }

    onClickFilter(e){
        e.preventDefault()
        var name = e.target.name

        this.setState({filterButton:name})

    }

    setCurrentEntry(entry){
        this.setState({currentEntry:entry})
    }

    pausePlayer(e){
        e.preventDefault();
        console.log("Pressed paused")
        var player = document.getElementById("audioplayer");
        this.setState({audioplay:''})      
        player.pause()
    
    }

    returnDivisionName(division_id){
        var division = this.state.division
        for (var i = 0; i < this.state.division.length; i++) {
            if (division[i].division_id == division_id){
                return division[i].division_name
            }
          } 
       
    }


    render(){
        var division = ''
        var
			config = {},
			tracks = {}
        ;
        var filterButton = this.state.filterButton
        var filteredEntrie = []
        var judgedEntry = this.state.entries.filter((entry)=>{
            return entry.score_list.length > 0
        })
        var notjudgedEntry = this.state.entries.filter((entry)=>{
            return entry.score_list.length === 0 
        })
        var allEntry = this.state.entries
        if (filterButton === 'judged'){
            filteredEntrie = judgedEntry
        }else if(filterButton=== 'notjudged'){
            filteredEntrie = notjudgedEntry

        }else{
            filteredEntrie = allEntry
        }
        return(

            <>
            <div style={{paddingBottom:'20px'}}>
                <style type="text/css">
                    {`
                    .list-group-item {
                        border:none;
                    }

                    .modal-95w{
                        width: 95%;
                        max-width:none !important;
                    }

                    

                    .modal-content {
                        height: auto;
                        min-height: 95%;
                      }

                    .carousel-indicators li{
                        background-color: black;
                        width: 50px;
                        height: 15px;
                    }

                    .fa-file-pdf, .fa-play, fa-pause, .fa-window-restore{
                        cursor: pointer;
                    }

                    .scoresheet{
                        position: 'absolute',
                        bottom: '0px',
                        width: '80%',
                        min-height:  '20px',
                        margin-top: '50px'

                    }

                    @media only screen and (max-width: 600px) {
                        .scoresheet{
                            position: 'relative',
    
                        }
                      }


                    </style>



                    `}
                </style>
                
                <Row>
                    <Col  xs={6}>
                    <h3>{this.state.category.category_name}</h3>
                    </Col>
                    <Col xs={6}>
                        <div className="justify-content-end">
                            <div className="float-right">
                                <Button onClick={(e)=>this.onClickFilter(e)} name="sequential" variant="primary" style={{display:'None'}}>
                                    Sequential Judging
                                </Button>{' '}
                                <Button onClick={(e)=>this.onClickFilter(e)} name="judged" variant="success">
                                    Judged <Badge variant="light">{judgedEntry.length}</Badge>
                                </Button>{' '}
                                <Button onClick={(e)=>this.onClickFilter(e)} name="notjudged" variant="danger">
                                    Not Judged <Badge variant="light">{notjudgedEntry.length}</Badge>
                                </Button>{' '}
                                <Button onClick={(e)=>this.onClickFilter(e)} name="all" variant="primary">
                                    All Entries <Badge variant="light">{allEntry.length}</Badge>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    (this.state.category.category_has_audio) ?
                <MediaElement
                    id="audioplayer"
                    mediaType="audio"
                    preload="none"
                    controls
                    width="400"
                    poster=""
                    sources={JSON.stringify(this.state.source)}
                    options={JSON.stringify(config)}
                    tracks={JSON.stringify(tracks)}
                    /> :<div></div>
                }
                <Accordion style={{cursor: 'pointer', backgroundColor: 'white', marginTop:'10px',marginBottom:'10px', fontSize: '14px'}} defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            <strong>Category Information</strong><a href={`${this.state.categorypdf}`} target="_blank"> 
                                                    <Button className="float-right" variant="warning">PDF</Button>
                                                </a>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div dangerouslySetInnerHTML={{__html: this.state.category.category_description}}/>
                                <hr/>
                                <div dangerouslySetInnerHTML={{__html: this.state.category.category_documentation_info}}/>
                                
                            </Card.Body>
                        </Accordion.Collapse>
                    
                    </Card>
                </Accordion>
                {
                    
                    (filteredEntrie.length> 0)? 
                        filteredEntrie.map((entry,k)=>
                            <div key={k}>
                            {
                                (this.state.category.category_id === 1026 || (this.state.category.category_id === 1031) ) ?
                                    (division != this.returnDivisionName(entry.division_id) + ' ' + entry.radio_format) ?
                                        <div>
                                            <span style={{display:'none'}}>{division = this.returnDivisionName(entry.division_id) + ' ' + entry.radio_format}</span>
                                            <h6>{division}</h6>
                                        </div>
                                    : <div></div>
                                :
                                    (division != entry.division_id) ?
                                    <div>
                                            <span style={{display:'none'}}>{division = entry.division_id}</span>
                                            <h6>{this.returnDivisionName(division)}</h6>
                                    </div>
                                    : <div></div>
                            }
                            
                            <Entry key={`entry-${entry.id}`} id={`entry-${k}`} division={this.returnDivisionName(division)}
                                specialquestions={this.state.specialquestions} 
                                entry={entry}
                                category={this.state.category}
                                panelid={this.state.panelid} 
                                audioplay={this.state.audioplay} 
                                setSourceList={this.setSourceList} 
                                pausePlayer={this.pausePlayer}
                                setCurrentEntry={this.setCurrentEntry}
                                setEntryScoreList={this.setEntryScoreList}
                                setModalPDFShow={this.setModalPDFShow}
                                setModalSpecialShow={this.setModalSpecialShow}
                                setModalInfoShow={this.setModalInfoShow}
                                setToast={this.setToast}
                                />
                            </div>
                        )
                    : <div>No Entries here</div>
                }
                {
                   (this.state.selectedEntry) ? (
                       <div>
                        <InfoViewer
                            entry = {this.state.selectedEntry}
                            division = {this.state.selectedDivision}
                            show={this.state.modalShowInfo}
                            onHide={(e) => this.setModalInfoShow(e,false,this.state.selectedDivision,this.state.selectedEntry)}
                        />
                        <PDFViewer
                            entry = {this.state.selectedEntry}
                            division = {this.state.selectedDivision}
                            show={this.state.modalShowPDF}
                            onHide={(e) => this.setModalPDFShow(e,false,this.state.selectedPDFlink,this.state.selectedDivision,this.state.selectedEntry)}
                            pdflink={this.state.selectedPDFlink}
                        />

                        <SpecialAnswers
                                entry = {this.state.selectedEntry}
                                division = {this.state.selectedDivision}
                                specialquestions = {this.state.specialquestions}
                                show={this.state.modalShowSpecial}
                                onHide={(e) => this.setModalSpecialShow(e,false,this.state.specialquestions,this.state.selectedDivision,this.state.selectedEntry)}
                            /> </div>) : null
                }
                <Modal dialogClassName="modal-60w" show={this.state.isFetching} animation={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <Spinner animation="border" variant="primary" />
                    </Modal.Body>
                </Modal>
                
            </div>
            <div style={{top:'20px', right: '20px', position: 'fixed', zIndex:'9999'}}>
                    <Toast onClose={() => this.setToast(false)} show={this.state.toastShow} delay={2000} autohide>
                        <Toast.Header style={{backgroundColor: '#d1e7dd'}}>
                            <strong className="me-auto">Score Updated</strong>
                        </Toast.Header>
                        {this.state.scoreEntry ? <Toast.Body>Updated {this.state.scoreEntry.entry_entrant_name} score from {this.state.scoreEntry.entry_station} </Toast.Body> : null}
                        
                    </Toast>
                </div>

                <div style={{display: 'None'}}>
                <MediaElement
                    id="videoplayer2"
                    mediaType="video"
                    preload="auto"
                    controls
                    width="100"
                    height="100"
                    poster=""
                    sources={JSON.stringify( [
                        {src: `https://youtu.be/Y0ija0oC9FE`, type:`video/youtube` },
                    ])}
                    options={JSON.stringify([])}
                    tracks={JSON.stringify([])}
                />
                </div>
            </>
        )
    }

}

function mapStateToProps(state) {

    const { loader , auth} = state
    const { isFetching, errorMessage, data } = loader
  
    return {
        errorMessage,
        data,
    }
}

export default connect(mapStateToProps)(UserEntries)