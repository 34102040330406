import React from 'react';

import { Col, Image,Container, Row,Nav, Navbar } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'

import { authOperations } from '../redux/auth/'



const topNav ={

}

const bottomNav ={

}

const middleNav={
    backgroundColor: '#fff',
    width: '100%',

    
}

class UserNav extends React.Component{
    constructor(props){
        super(props)
    
    }

    componentDidMount() {
    
    }

    logoutOnClick(e){
        e.preventDefault()
        const {dispatch} = this.props
        dispatch(authOperations.logoutUser())
        window.location = '/'
    }

    render(){
        
        return(
            <div>
                <Container>
                    <Row>
                        <Col>
                            <Image style={{marginTop:'10px', marginBottom:'20px'}} src="/dist/img/ACRAs2021-White_175x100.png" fluid />
                        </Col>
                        
                    </Row>
                </Container>
                <Row >
                    <div style={topNav}></div>
                </Row>
                <Row>
                    <div style={middleNav}>
                    <Container>
                        <style type="text/css">
                            {`
                            .nav-item a {
                                color: #1e1e1e;
                                background: url(/dist/img/dot.png) no-repeat 0 15px;
                                padding: 0 0 0 9px;
                            }

                            .nav-item a{
                                font-family: 'Open Sans', 'Myriad Pro', sans-serif;
                                display: block;
                                padding: 5px 15px;
                                color: #222222;
                                border-radius: 6px;
                                border: 1px solid transparent;
                                border-top: 0;
                                border-left: 0;
                                text-decoration: none;

                            }

                            .nav-item a:hover{
                                color: #fff;
                                background: #222222;
                                border-color: #DAD7D7;

                            }



                            `}
                        </style>
                        <Navbar style={{background:'url(/dist/images/main-menu-gradient.png) no-repeat bottom center'}} expand="lg" >
                            <Navbar.Toggle aria-controls="basic-navbar-nav"  className="justify-content-center"/>
                            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                                <Nav
                                    activeKey="/home">
                                    <Nav.Item>
                                        <Link to="/portal/judge">
                                            JUDGING PORTAL
                                        </Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <a onClick={(e) => this.logoutOnClick(e)}>
                                            LOGOUT
                                        </a>
                                    </Nav.Item>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Container>
                    </div>
                </Row>
                <Row >
                    <div style={bottomNav}></div>
                </Row>
            </div>
        )
    }

}

function mapStateToProps(state) {

    const { auth } = state
    const { isAuthenticated, errorMessage } = auth
  
    return {
      isAuthenticated,
      errorMessage
    }
}

export default connect(mapStateToProps)(UserNav)