import React from 'react';
import { Alert, Image,Container, Row,Form, Button } from 'react-bootstrap';


import { connect } from 'react-redux'

import { authOperations } from '../redux/auth/'
import PropTypes from 'prop-types';

import axios from 'axios'

import variables from '../redux/variables';


const backgroundStyle = {
    backgroundColor: 'black',
  };

const fontColor = {
    color: 'white',

}

const logoSpacing= {
    marginBottom: '20px',
}
  

class UserSignIn extends React.Component{
    constructor(props){
        super(props)

        this.handleSignIn = this.handleSignIn.bind(this)
    
    }

    componentDidMount() {
    
    }

    
    handleSignIn(e){
        e.preventDefault()
        const { dispatch } = this.props
        const username = this.refs.username
        const password = this.refs.password
        const creds={username: username.value.trim(), password: password.value.trim()}
        dispatch(authOperations.loginUser(creds))
    }

    render(){
        var d = new Date();
        var n = d.getFullYear();
        const { isAuthenticated, errorMessage } = this.props
        return(
            <div className="d-flex align-items-center min-vh-100" style={backgroundStyle}>
                <Container>
                   
                    <Row className="text-center justify-content-md-center">
                        <Form>
                            <Image style={logoSpacing} src="/dist/img/ACRAs2021-White_175x100.png" fluid />
                            {isAuthenticated ?  this.props.history.push('/portal'):null}
                                    {(errorMessage) ? 
                                        <Alert variant="danger">
                                        {errorMessage}
                                        </Alert>:
                                        null
                                    }
                            <h4 style={fontColor}>Please sign in</h4>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control ref='username' type="email" placeholder="Enter email" />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Control  ref='password' type="password" placeholder="Password" />
                            </Form.Group>
                            <Button variant="primary" size="lg" block onClick={(e) => this.handleSignIn(e)}>
                                Sign In
                            </Button>
                            <sub style={fontColor}>{ n }</sub>
                        </Form>
                    </Row>
                </Container>
            </div>
        )
    }

}

UserSignIn.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
}

function mapStateToProps(state) {

    const { auth } = state
    const { isAuthenticated, errorMessage } = auth
  
    return {
      isAuthenticated,
      errorMessage
    }
}

export default connect(mapStateToProps)(UserSignIn)