import React from 'react';
import logo from './logo.svg';

import { Switch, Route, BrowserRouter } from 'react-router-dom'
import UserMain from './components/UserMain'
import UserSignIn from './components/UserSignIn'
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlay, faFilePdf, faListAlt, faFilm, faPause, faWindowRestore, faInfo, faPhotoVideo} from '@fortawesome/free-solid-svg-icons'

library.add(faPlay, faFilePdf,faListAlt, faFilm, faPause, faWindowRestore, faInfo, faPhotoVideo)

const Whoops404 = () => (
	<div className="">
		Page does not exist!
		
    </div>
);

class App extends React.Component {
  render(){
    return (
        <BrowserRouter>
          <Switch>
            <Route exact path ='/' component={UserSignIn}/> 
            <Route path ='/portal' component={UserMain}/>
            <Route path='*' component={Whoops404}/>
          </Switch>
        </BrowserRouter>
    )
  }

}

export default App;

