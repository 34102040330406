import React from 'react';



import UserNav from './UserNav'
import UserFooter from './UserFooter'
import { Col, Modal,Container, Row,Spinner } from 'react-bootstrap';
import { Switch, Route,NavLink,Redirect, matchPath } from 'react-router-dom'

//Page imports
import UserPage from './UserPage'
import UserProfile from './UserProfile'
import UserJudging from './UserJudging'
import UserEntries from './UserEntries'
import Template from './Template'
import { connect } from 'react-redux'


const contentStyle ={
    backgroundColor: 'white',
    minHeight: '400px',
    marginTop: '35px',
    paddingLeft: '60px',
    paddingRight: '60px',
    borderRadius: '6px',
    paddingTop: '20px'
}

class UserMain extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            modalIsOpen: false,
            isFetching: false,
        }
        this.navHeader = this.navHeader.bind(this)
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.setFetching = this.setFetching.bind(this);

        
    
    }


    setFetching(isFetch){
        this.setState({isFetching:isFetch})
    }

    componentDidMount() {
        document.body.style.background = "url(/dist/img/body-bg.jpg)";
    
    }

    navHeader(page){
        this.setState({ headerActive:page})
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

      closeModal() {
        this.setState({modalIsOpen: false});
    }

    
    componentWillUnmount(){
        document.body.style.background = null;
    }

    render(){
        return(
            <div>
                <style type="text/css">
                            {`
                           .modal-60w {
                                width: 60px;
                                max-width: none!important;
                                padding: 0;
                            }

                            select {
                                text-align: center;
                                text-align-last: center;
                                font-size: 10px;
                              }
                              option {
                                text-align: center;
                              }

                           



                            `}
                </style>
                <Modal dialogClassName="modal-60w" show={this.state.isFetching} animation={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <Spinner animation="border" variant="primary" />
                    </Modal.Body>
                </Modal>
                <UserNav/>
                <Container>
                    <Row>
                        <Col style={contentStyle} xs={12} md={12}>
                        {
                            <Switch>
                                <Route exact path ='/portal' key={1} component={UserJudging}/> 
                                <Route exact path ='/portal/judge' key={2} component={UserJudging}/> 
                                <Route exact path ='/portal/judge/:panelid/:categoryid' 
                                    key={3} component={(props)=><UserEntries {...props} 
                                    panelid={this.props.match.params.panelid} 
                                    categoryid={this.props.match.params.categoryid}
                                    final={false}
                                    />}
                                />
                                <Route exact path ='/portal/final/judge/:panelid/:categoryid' 
                                    key={4} component={(props)=><UserEntries {...props} 
                                    panelid={this.props.match.params.panelid} 
                                    categoryid={this.props.match.params.categoryid}
                                    final={true}
                                    />}
                                />
                                
                            </Switch>
                        }
                        </Col>
                    </Row>
                </Container>
                <UserFooter/>
            </div>
        )
    }

}

function mapStateToProps(state) {

    const { loader } = state
    const { isFetching } = loader
  
    return {
        isFetching,
    }
}

export default connect(mapStateToProps)(UserMain)