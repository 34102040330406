import React from 'react';
import { connect } from 'react-redux'
import { loaderOperations } from '../redux/loader/'
import { Carousel, Row, Col, Modal, Button, Form,Table } from 'react-bootstrap';
import MediaElement from './MediaElement'

import EntryInfo from './EntryInfo'
import { isURL, checkVideoFormat  } from './Helpers'

class EntryJudgePanel extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            index: 0,
            setIndex: 0,
        }

        this.handleSelect = this.handleSelect.bind(this)
    }


    componentDidMount() {
    
    }

    async handleSelect(selectedIndex, e){
        this.setState({index:selectedIndex})
    }

    returnSpecialQuestionsFromId(question_id){
        var specialquestions = this.props.specialquestions
        for (var i = 0; i < specialquestions.length; i++) {
            if (specialquestions[i].question_id == question_id){
                return specialquestions[i].question_text
            }
          } 
       
        
    }
    

    render(){
        const entry = this.props.entry
        const score = Array.from(Array(11).keys())
        const { dispatch, panelid } = this.props
        if (entry.audio_list.length > 0){
            var source = [
                {src: `/media/entries/audio/${entry.audio_list[0].audio_file}`, type: 'audio/mp3'},
            ]
        }else{
            var source = []
        }
        return(
            <Modal
                    show={this.props.show}
                    onHide={this.props.onHide}
                    dialogClassName="modal-95w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                        
                    <Modal.Header closeButton>
                        
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                                <Col xs={12} md={9}>
                                    <Carousel 
                                        activeIndex={this.state.index} 
                                        onSelect={this.handleSelect}
                                        interval= {null}
                                        controls={false}
                                        >
                                        {
                                            entry.pdf_list.map((pdf,k)=>
                                                <Carousel.Item key={k}>
                                                    <h3>Entry Supporting Material</h3>
                                                    <embed id={`pdf-${this.state.index}`} src={`/media/entries/pdfs/${pdf.pdf_file}`}
                                                     style={{width:'100%',height:'85vh'}}/>
                                                </Carousel.Item>
                                            )
                                        }
                                        {
                                            (entry.answer_list.length > 0 ) ?
                                                    <Carousel.Item style={{width:'100%',height:'85vh'}}>
                                                        <h2>Special questions</h2>
                                                        {
                                                        entry.answer_list.map((answer,k)=>
                                                                <div key={k}>
                                                                <strong>
                                                                    <div  dangerouslySetInnerHTML={{__html: this.returnSpecialQuestionsFromId(answer.question_id)}}></div>
                                                                </strong>
                                                                {
                                                                       ( isURL(answer.answer_text)) ?
                                                
                                                                       <MediaElement
                                                                       id="videoplayer"
                                                                       mediaType="video"
                                                                       preload="auto"
                                                                       controls
                                                                       width="320"
                                                                       height="240"
                                                                       poster=""
                                                                       sources={JSON.stringify( [
                                                                           {src: `${answer.answer_text}`, type:`${checkVideoFormat(answer.answer_text)}` },
                                                                       ])}
                                                                       options={JSON.stringify([])}
                                                                       tracks={JSON.stringify([])}
                                                                   />
                                                               
                                                                    :
                                                                    <div  dangerouslySetInnerHTML={{__html: answer.answer_text}}></div> 
                                                                }
                                                                <br />
                                                                </div>
                                                    )}</Carousel.Item>:null
                                                
                                        }
                                    </Carousel>
                                    
                                </Col>
                                <Col xs={12} md={3}>
                                    <h4>Entry Infomation</h4>
                                    <EntryInfo division={this.props.division} entry={entry}/>
                                    {
                                    (entry.audio_list.length > 0) ?
                                        <div>
                                        <h4>Entry Audio</h4>
                                        <MediaElement
                                                id="audioplayer"
                                                mediaType="audio"
                                                preload="none"
                                                controls
                                                width="auto"
                                                poster=""
                                                sources={JSON.stringify(source)}
                                                options={JSON.stringify([])}
                                                tracks={JSON.stringify([])}
                                        /></div>: <div></div>
                                    }
                                    
                                     <Form className="scoresheet">
                                        <Form.Group as={Row} controlId={`formScorejp.score-${entry.entry_id }`}>
                                            <Form.Label column sm="2">
                                                <h6>Score</h6>
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control onChange={(e)=>this.props.onChangeScore(e)} 
                                                    value={this.props.score} 
                                                    as="select" 
                                                    size="lg"
                                                    style={{fontSize:'15px', fontWeight:'800'}}>
                                                    {
                                                        score.map((item,k) =>
                                                        <option key={k} value={item}>{item}</option>

                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e)=>this.props.onHide(e)}>Close</Button>
                    </Modal.Footer>
            </Modal>
            
        )
    }

}


function mapStateToProps(state) {

    const { loader } = state
    const { isFetching, errorMessage, data } = loader
  
    return {
        isFetching,
        errorMessage,
        data,
    }
}

export default connect(mapStateToProps)(EntryJudgePanel)
