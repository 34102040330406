import React from 'react';
import { connect } from 'react-redux'
import { loaderOperations } from '../redux/loader/'
import { Card, ListGroup,Container, Row,Col, Button, Badge } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom'



class UserJudging extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            judgepanel: []
        }
        
    }

    async componentDidMount() {
        const { dispatch } = this.props
        await dispatch(loaderOperations.getData('api/profile/'))
        var user =this.props.data.results[0]
        await dispatch(loaderOperations.getData('api/judgestatus/'))
        var status =this.props.data
        console.log(status)
        const new_categorys = []
        var judgepanel = user.userprofile.judgepanel
        var final = false
        for (let i =0; i < judgepanel.length; i++ ){
            let panel = judgepanel[i]
            if (panel.final){
                final = true
            }
            for (let k=0; k < panel.categorys.length; k++){
                let category = panel.categorys[k]
                for (let j = 0; j < status.length; j++){
                    let element = status[j]
                    if (element.judgepanel === panel.id && element.category === category.category_id ){
                        category.entrycount = element.entrycount
                        category.scorecount = element.scorecount
                        new_categorys.push(category)
                        
                    }
                }
            }
        }
        if (final){
            ///api/judgecategory/
            await dispatch(loaderOperations.getData('api/judgecategory/'))
            var finalcategory =this.props.data
            this.setState({
                finalcategory: finalcategory,
            })
        }
        judgepanel.categorys = new_categorys
        this.setState({
            judgepanel: judgepanel,
        })
    }

    render(){
        const judgepanel = this.state.judgepanel
        return(
            <Container>
                
                <h2 style={{marginBottom: '20px'}}>Judging Panel</h2>
                <Row>
                {(judgepanel) ?
                   
                    judgepanel.map((item,i)=>
                            <Col xs={12} key={i}>
                                <Card name={i} style={{ width: '100%', marginBottom: '20px' }}>
                                    <Card.Header><strong>{item.name}</strong></Card.Header>
                                        
                                            {
                                            (item.categorys) ?
                                            <ListGroup variant="flush">
                                                {
                                                    item.categorys.map( (category,i) =>
                                                        (!item.final) ?
                                                            <ListGroup.Item key={i} name={i}>{item.final} {category.category_name}
                                                                <Link to={`/portal/judge/${item.id}/${category.category_id}`} >
                                                                    <Button style={{marginLeft: '10px'}} className="float-right" variant="secondary">Judge</Button>
                                                                </Link> 
                                                                {
                                                                (category.entrycount === category.scorecount) ? 
                                                                    <div className="float-right">  
                                                                        <Button variant="success">Completed</Button>
                                                                    </div>
                                                                    :
                                                                    <div className="float-right">  
                                                                        <Button variant="warning">{category.scorecount} / {category.entrycount} </Button>
                                                                    </div>
                                                                }
                                                                
                                                            </ListGroup.Item>: 
                                                             this.state.finalcategory.filter(dcategory => dcategory.d_category__category_id === category.category_id).map((category,i)=>
                                                             <ListGroup.Item name={i} key={i}>{category.d_category__category_name}
                                                                 <Link to={`/portal/final/judge/${item.id}/${category.d_category__category_id}`}> 
                                                                     <Button style={{marginLeft: '10px'}} className="float-right" variant="secondary">Judge</Button>
                                                                 </Link>
                                                                 {
                                                                 (category.total === category.scorecount) ? 
                                                                     <div className="float-right">  
                                                                         <Button variant="success">Completed</Button>
                                                                     </div>
                                                                     :
                                                                     <div className="float-right">  
                                                                         <Button variant="warning">{category.scorecount} / {category.total} </Button>
                                                                     </div>
                                                                 }
                                                             </ListGroup.Item>
                                                         )

                                                    )
                                                   
                                                }
                                                {
                                                     ((item.categorys.length) === 0 && item.final) ?
                                                     <>
                                                     {
                                                        
                                                        this.state.finalcategory.map((category,i)=>
                                                            <ListGroup.Item name={i} key={i}>{category.d_category__category_name}
                                                                <Link to={`/portal/final/judge/${item.id}/${category.d_category__category_id}`}> 
                                                                    <Button style={{marginLeft: '10px'}} className="float-right" variant="secondary">Judge</Button>
                                                                </Link>
                                                                {
                                                                (category.total === category.scorecount) ? 
                                                                    <div className="float-right">  
                                                                        <Button variant="success">Completed</Button>
                                                                    </div>
                                                                    :
                                                                    <div className="float-right">  
                                                                        <Button variant="warning">{category.scorecount} / {category.total} </Button>
                                                                    </div>
                                                                }
                                                            </ListGroup.Item>
                                                        )
                                                        
                                                    } </>: <div></div>
                                                }
                                              
                                            </ListGroup>
                                            : <div>No Categories assigned</div>
                                            }
                                        
                                </Card>
                            </Col>
                        
                    ):
                    <div>no stuff to judge</div>
                }
                </Row>
            </Container>
        )
    }

}


function mapStateToProps(state) {

    const { loader } = state
    const { isFetching, errorMessage, data } = loader
  
    return {
        isFetching,
        errorMessage,
        data,
    }
}

export default connect(mapStateToProps)(UserJudging)