import axios from 'axios'

import actions from './actions';

import variables from '../variables';

const requestLogin = actions.requestLogin
const receiveLogin = actions.receiveLogin
const loginError = actions.loginError
const requestLogout = actions.requestLogout
const receiveLogout = actions.receiveLogout



export function loginUser(creds) {

    return dispatch => {
        dispatch(requestLogin(creds))

        return axios.post(variables.apiURL+'rest-auth/login/', { username: '',email:`${creds.username}`, password: `${creds.password}` })
        .then(function(response){
            localStorage.setItem('id_token', response.data.key)
         
            dispatch(receiveLogin(response.data.token))
        })
        .catch(function(error){
            if (error.response){
                //console.log(error.response.data.non_field_errors[0])
                dispatch(loginError(error.response.data.non_field_errors[0]))
            }else{
              dispatch(loginError('Error Logging into server'))  
            }
        });
    }
}

export function loginSocialUser(token,url) {

    return dispatch => {
        dispatch(requestLogin(token))

        return axios.post(variables.apiURL+url, { access_token: `${token}`})
        .then(function(response){
            localStorage.setItem('id_token', response.data.key)
         
            dispatch(receiveLogin(response.data.token))
        })
        .catch(function(error){
            if (error.response){
                //console.log(error.response.data.non_field_errors[0])
                dispatch(loginError(error.response.data.non_field_errors[0]))
            }else{
              dispatch(loginError('Error Logging into server'))  
            }
        });
    }
}

export function logoutUser() {
    return dispatch => {
      dispatch(requestLogout())
      localStorage.removeItem('id_token')
      return axios.post(variables.apiURL+'rest-auth/logout/')
      .then(function(response){
        dispatch(receiveLogout())
      })
      .catch(function(error){
        dispatch(receiveLogout())
      });

    }
  }

export default {
    loginUser,
    logoutUser,
    loginSocialUser
}