import React from 'react';

class Template extends React.Component{
    constructor(props){
        super(props)
    
    }

    componentDidMount() {
    
    }

    render(){
        return(
            <div>
                THIS IS TEMPLATE
            </div>
        )
    }

}

export default Template;