
import types from './types';


//var apiURL = 'http://localhost:9999/'
// There are three possible states for our login
// process and we need actions for each of them


function requestData() {
  return {
    type: types.DATA_REQUEST,
    isFetching: true,
  }
}

function receiveData(data) {
  return {
    type: types.DATA_SUCCESS,
    isFetching: false,
    data
  }
}

function dataError(message) {
  return {
    type: types.DATA_FAILURE,
    isFetching: false,
    message
  }
}

function sendData(data) {
  return {
    type: types.DATA_SEND,
    isFetching: true,
    data
  }
}

function dataClear(){
  return{
    type: types.DATA_CLEAR,
    isFetching: false,
  }

}




  export default{
    requestData,
    receiveData,
    dataError,
    dataClear,
    sendData
  }

