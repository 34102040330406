import React from 'react';
import { connect } from 'react-redux'

import { loaderOperations } from '../redux/loader/'
import { authOperations } from '../redux/auth/'
import { Redirect } from 'react-router-dom'




class UserPage extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            
        }
    
    }

    async componentDidMount() {
        const { dispatch, pageTitle } = this.props
        await dispatch(loaderOperations.getData(`api/page/${pageTitle}/`))
        //this.setState({content:this.props.data.content})
        if (!this.props.data?.content){
            await dispatch(authOperations.logoutUser())
            window.location = "/"
        }
    
    }

    async componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.pageTitle !== this.props.pageTitle) {
            const { dispatch, pageTitle } = this.props
            await dispatch(loaderOperations.getData(`api/page/${pageTitle}/`))
            if (!this.props.data?.content){
                await dispatch(authOperations.logoutUser())
                window.location = "/"
            }
            //this.setState({content:this.props.data.content})
        }
      }

    render(){
        const htmlInner = { 
            paddingTop: '20px'

        }
       
        return(
            <div style={htmlInner}>
                { (this.props.data?.content) ?
                    <span dangerouslySetInnerHTML={ {__html:this.props.data.content}} />
                    :  null

                   
                    
                }
            </div>
        )
    }

}

function mapStateToProps(state) {

    const { loader } = state
    const { isFetching, errorMessage, data } = loader
  
    return {
        isFetching,
        errorMessage,
        data,
    }
}


export default connect(mapStateToProps)(UserPage);