import axios from 'axios'

import actions from './actions';

import variables from '../variables';

const requestData = actions.requestData
const receiveData = actions.receiveData
const dataError = actions.dataError
const dataClear = actions.dataClear
const sendData = actions.sendData

export function patchData(url,data) {

    return dispatch => {
        dispatch(sendData(data))

        return axios.patch(variables.apiURL+url, data, {headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}})
        .then(function(response){
           
         
            dispatch(receiveData(response))
        })
        .catch(function(error){
            if (error.response){
                //console.log(error.response.data.non_field_errors[0])
                //dispatch(DataError(error.response.data.non_field_errors[0]))
                dispatch(dataError('Error Logging into server'))  
            }else{
              dispatch(dataError('Error Logging into server'))  
            }
        });
    }
}


export function postData(url,data) {

    return dispatch => {
        dispatch(sendData(data))

        return axios.post(variables.apiURL+url, data, {headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}})
        .then(function(response){
           
         
            dispatch(receiveData(response))
        })
        .catch(function(error){
            if (error.response){
                //console.log(error.response.data.non_field_errors[0])
                //dispatch(DataError(error.response.data.non_field_errors[0]))
                dispatch(dataError('Error Logging into server'))  
            }else{
              dispatch(dataError('Error Logging into server'))  
            }
        });
    }
}


export function putData(url,data) {

    return dispatch => {
        dispatch(sendData(data))

        return axios.put(variables.apiURL+url, data, {headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}})
        .then(function(response){
           
         
            dispatch(receiveData(response))
        })
        .catch(function(error){
            if (error.response){
                //console.log(error.response.data.non_field_errors[0])
                //dispatch(DataError(error.response.data.non_field_errors[0]))
                dispatch(dataError('Error Logging into server'))  
            }else{
              dispatch(dataError('Error Logging into server'))  
            }
        });
    }
}


export function getOptions(url) {

    return dispatch => {
        dispatch(requestData())

        return axios.options(variables.apiURL+url, {headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}})
        .then(function(response){
           
         
            dispatch(receiveData(response.data))
        })
        .catch(function(error){
            if (error.response){
                //console.log(error.response.data.non_field_errors[0])
                //dispatch(DataError(error.response.data.non_field_errors[0]))
                dispatch(dataError('Error Logging into server'))  
            }else{
              dispatch(dataError('Error Logging into server'))  
            }
        });
    }
}



export function getData(url) {

    return dispatch => {
        dispatch(requestData())

        return axios.get(variables.apiURL+url, {headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}})
        .then(function(response){
           
         
            dispatch(receiveData(response.data))
        })
        .catch(function(error){
            if (error.response){
                //console.log(error.response.data.non_field_errors[0])
                //dispatch(DataError(error.response.data.non_field_errors[0]))
                dispatch(dataError('Error Logging into server'))  
            }else{
              dispatch(dataError('Error Logging into server'))  
            }
        });
    }
}

export function clearData(){
    return dispatch => {
        dispatch(dataClear())
    }
}

export default {
    getData,
    clearData,
    getOptions,
    putData,
    postData,
    patchData,
}