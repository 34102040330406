import React from 'react';
import {Table } from 'react-bootstrap';

class EntryInfo extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        const entry = this.props.entry
        return(
            <Table responsive>
                <style type="text/css">
                    {`
                    tbody {
                       font-size: 12px;
                    }
                    `}
                </style>
                <tbody>
                    {/*
                        <tr>
                            <th>Entry Id</th>
                            <td>{entry.entry_number}</td>
                        </tr>
                       */
                    }
                    <tr>
                        <th>Division</th>
                        <td>{this.props.division}</td>
                    </tr>
                    <tr>
                        <th>Name of Entrant</th>
                        <td>{entry.entry_entrant_name}</td>
                    </tr>
                    <tr>
                        <th>Title of Entry</th>
                        <td>{entry.entry_title}</td>
                    </tr>
                    <tr>
                        <th>Station Name</th>
                        <td>{entry.entry_station}</td>
                    </tr>
                    <tr>
                        <th>City and State</th>
                        <td>{entry.entry_city} {entry.entry_state}</td>
                    </tr>
                    {/*
                        <tr>
                            <th>Entry Contact</th>
                            <td>{entry.entry_contact_name} {entry.entry_contact_phone} <br />
                                {entry.entry_contact_email}
                            </td>
                        </tr>
                      */
                    }
                </tbody>
            </Table>
        )

    }
}

export default (EntryInfo)