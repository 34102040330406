export function isURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(str);
  }

export function checkVideoFormat(str){
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i');
    var a  = str.match(pattern);
    if (a.includes("youtube")|| a.includes("youtu"))
    { 
        return "video/youtube"
    }
    else if (a.includes("vimeo.com"))
    {
        return "video/vimeo"
    }
    else
    {
        return "video/mp4"
    }
}

export function isVideoplayable(str){
    var a  = str
    if (a.includes("youtube")|| a.includes("youtu"))
    { 
        return true
    }
    else if (a.includes("vimeo.com"))
    {
        return true
    }
    else if (a.includes("dropbox"))
    {
        return true
    }
    else
    {
        return false
    }
}