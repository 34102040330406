import React from 'react';
import { Alert, Col,Container, Row,Form, Button } from 'react-bootstrap';

import { connect } from 'react-redux'
import { loaderOperations } from '../redux/loader/'

class UserProfile extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            username: '',
            first_name: '',
            last_name:'',
            email:'',
            userprofile:{
                station: '',
                network: '',
            }

        }
        this.saveProfile = this.saveProfile.bind(this)
    
    }

    saveProfile(e){
        e.preventDefault()
        const { dispatch } = this.props
        //this.setState({profile:{country:this.countrySelect.value}})
        //console.log(this.state.profile.country)
        dispatch(loaderOperations.putData(`api/profile/${this.state.username}/`,this.state))
    }

    async componentDidMount() {
        var current = this
        const { dispatch } = this.props

        await dispatch(loaderOperations.getData('api/profile/'))
        var user =this.props.data.results[0]
        this.setState({
            username: user.username,
            first_name:user.first_name,
            last_name:user.last_name,
            email:user.email,
            userprofile:{
                station: user.userprofile.station,
                network: user.userprofile.network,
            }

        })
        dispatch(loaderOperations.clearData())
        

    
    }

    inputChangedHandler = (event) => {
        var name = event.target.name
        if (name.includes(".")){
            var sections = name.split(".")
            var userprofile = this.state.userprofile
            userprofile[sections[1]] = event.target.value
            this.setState({userprofile});
        }else{
            this.setState({[event.target.name]: event.target.value});
        }
        
        // May be call for search result
    }

    render(){
        var formFields= [
            {controlId:'first_name',label:'First name', type:"text", placeholder:"Enter First name", formText:'', value:this.state.first_name},
            {controlId:'last_name',label:'Last name', type:"text", placeholder:"Enter Last name", formText:'', value:this.state.last_name},
            {controlId:'email',label:'Email', type:"email", placeholder:"Enter email address", formText:'', value:this.state.email},
            {controlId:'userprofile.station',label:'Station', type:"text", placeholder:"Enter station", formText:'', value:this.state.userprofile.station},
            {controlId:'userprofile.network',label:'Network', type:"text", placeholder:"Enter network", formText:'', value:this.state.userprofile.network},
        ]
        return(
            <Container fluid>
                <h2>Profile</h2>
                <Row>
                    <Col>
                        <Form>
                            {
                                formFields.map((field) =>
                                    <Form.Group as={Row} controlId={`controlId${field.controlId}`}>
                                    <Form.Label column sm="2">{field.label}</Form.Label>
                                    <Col sm="10">
                                    <Form.Control name={`${field.controlId}`} value={field.value} type={field.type} placeholder={field.placeholder} onChange={(event)=>this.inputChangedHandler(event)}/>
                                    { (field.formText) ? 
                                    <Form.Text className="text-muted">
                                        field.formText
                                    </Form.Text> : null
                                    }
                                    </Col>
                                </Form.Group>
                                )
                            }
                            <Row>
                                <Col sm="2">
                                </Col>
                                <Col sm="10">
                                    <Button variant="success" type="submit"  size="lg">
                                        Rest password
                                    </Button> { }
                                    <Button onClick={((e)=>this.saveProfile(e))} variant="primary" type="submit"  size="lg">
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                           
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }

}

function mapStateToProps(state) {

    const { loader } = state
    const { isFetching, errorMessage, data } = loader
  
    return {
        isFetching,
        errorMessage,
        data,
    }
}

export default connect(mapStateToProps)(UserProfile)