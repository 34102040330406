import React from 'react';
import { connect } from 'react-redux'
import { loaderOperations } from '../redux/loader/'
import { Container, Card, Row, Col, Modal, Button, ListGroup,Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from 'react-bootstrap';
import EntryJudgePanel from './EntryJudgePanel'

import axios from 'axios';
import variables from '../redux/variables';


import MediaElement from './MediaElement'








class Entry extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            modalShowSpecial: false,
            modalShowPDF: false,
            modalShowJudgePanel: false,
            pdflink: '',
            score: 0,
            scorenew: false,
            score_id: 0,
            entry_id: 0,
        }

        this.setModalSpecialShow = this.setModalSpecialShow.bind(this)
        this.setModalPDFShow = this.setModalPDFShow.bind(this)
        this.setModalJudgePanelShow = this.setModalJudgePanelShow.bind(this)
        this.onChangeScore = this.onChangeScore.bind(this)
    
    }

    componentDidMount() {
        var entry = this.props.entry
        this.setState({entry_id:entry.id})
        if (entry.score_list.length > 0) {
            this.setState({score:entry.score_list[0].score,scorenew:false, score_id:entry.score_list[0].id})
        }else{
            this.setState({scorenew:true})
        }

    
    }

    setModalSpecialShow(e,show){
        this.setState({modalShowSpecial: show});
    }

    setModalPDFShow(e,show, pdflink){
        this.setState({modalShowPDF: show, pdflink:pdflink});
    }

    setModalJudgePanelShow(e,show){
        const { setCurrentEntry, id} = this.props
        this.setState({modalShowJudgePanel: show});
        setCurrentEntry(id)
    }


    postData(url,data){
        var current = this
        axios.post(variables.apiURL+url, data, {headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}})
        .then(function(response){
           
            current.setState({scorenew:false, score_id:response.data.id})
            current.props.setEntryScoreList(current.state.entry_id,[response.data])
            //return response
        })
        .catch(function(error){
            if (error.response){
                 
            }else{
                
            }
        });
    }

    putData(url,data){
        var current = this
        axios.put(variables.apiURL+url, data, {headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}})
        .then(function(response){
           
            //current.setState({scorenew:false, score_id:response.data.id})
            current.props.setEntryScoreList(current.state.entry_id,[response.data])
            //return response
        })
        .catch(function(error){
            if (error.response){
                 
            }else{
                
            }
        });
    }

    async onChangeScore(e){
        const { dispatch, panelid} = this.props
        var value = e.target.value
        this.setState({score:value})
        var entry = this.props.entry
        var score_data = []
        if (this.state.scorenew){
            var data = await this.postData(`api/score/`,{judgepanel:panelid,entry:this.state.entry_id,score:value, category:entry.d_category})
            //console.log(data)
            //this.setState({scorenew:false, score_id:data.data.id})
            //score_data = [data.data]

        }else{
            this.setState({scorenew:false})
            var data = await this.putData(`api/score/${this.state.score_id}/`,{judgepanel:panelid,id:this.state.score_id,entry:this.state.entry_id,score:value,category:entry.d_category})

        }

        this.props.setToast(true,entry)
        

    }



    render(){
        
        var entry = this.props.entry
        const score = Array.from(Array(11).keys())
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
              { this.props.message}
            </Tooltip>
          );
        

         const styleSelect = {
             textAlign: 'center',
             textAlignLast: 'center',
         }

         var conditions = ["Multimedia","Digital Radio"]
         var category_name = this.props.category.category_name
        return(
            
            <Card style={{marginBottom:'5px', fontSize: '14px'}} id={this.props.id} body>
                
               
                <Row>
                    <Col xs={12} md={6}>
                        {
                            ( entry.entry_title != entry.entry_entrant_name ) ?
                            <>
                                <Row>
                                    <div style={{cursor: 'pointer'}} onClick={(e)=>this.props.setModalInfoShow(e,true,this.props.division,entry)}>
                                        <strong>{entry.entry_title}</strong>
                                    </div>
                                </Row>
                                <Row>
                                    <div style={{cursor: 'pointer'}} onClick={(e)=>this.props.setModalInfoShow(e,true,this.props.division,entry)}>
                                        <strong>{entry.entry_entrant_name}</strong>
                                    </div>
                                </Row>
                            </>
                            :
                            <Row>
                                <div style={{cursor: 'pointer'}} onClick={(e)=>this.props.setModalInfoShow(e,true,this.props.division,entry)}>
                                    <strong>{entry.entry_entrant_name}</strong>
                                </div>
                            </Row>
                         }
                        <Row>
                            <div style={{cursor: 'pointer'}} onClick={(e)=>this.props.setModalInfoShow(e,true,this.props.division,entry)}>
                                {entry.entry_station}
                            </div>
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <ListGroup horizontal className="float-right">
                            {
                                entry.audio_list.map((audio,k)=>
                                
                                    <ListGroup.Item key={k}>
                                        {
                                            
                                                (this.props.audioplay === audio.id)?
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 100 }}
                                                    overlay={<Tooltip id="button-tooltip audio" >
                                                                Pause
                                                            </Tooltip>}
                                                    >   
                                                    <FontAwesomeIcon key={k} onClick={(e)=>this.props.pausePlayer(e)} icon="pause" size="2x"/>
                                                </OverlayTrigger>
                                                :
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 100 }}
                                                    overlay={<Tooltip id="button-tooltip audio" >
                                                                Play
                                                            </Tooltip>}
                                                    > 
                                                        <FontAwesomeIcon key={k} onClick={(e)=>this.props.setSourceList(e,audio)} icon="play" size="2x"/>
                                                </OverlayTrigger>
                                        }
                                    </ListGroup.Item >
                                )
                            }
                            {
                                entry.pdf_list.map((pdf,k)=>
                                    <span key={k}>
                                    <ListGroup.Item  >
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 100 }}
                                            overlay={<Tooltip id={ `button-tooltip-support-${k}`} >
                                                        Supporting Material {k}
                                                    </Tooltip>}
                                         > 
                                            <FontAwesomeIcon icon="file-pdf" size="2x" onClick={(e) => this.props.setModalPDFShow(e,true,`${variables.mediaURL}media/entries/pdfs/${pdf.pdf_file}`,this.props.division,entry)}/>
                                        </OverlayTrigger>
                                    </ListGroup.Item >
                                    </span>
                                )
                            }
                            {
                                (entry.answer_list.length > 0) ?
                                <span>
                                <ListGroup.Item >
                                    <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 100 }}
                                            overlay={<Tooltip id={ `button-tooltip-special-questions`} >
                                                        Special Questions
                                                    </Tooltip>}
                                    > 
                                    {
                                    (conditions.some(el=> category_name.includes(el)))?
                                        <FontAwesomeIcon style={{cursor:'pointer'}} icon="photo-video" size="2x" onClick={(e) => this.props.setModalSpecialShow(e,true,this.props.specialquestions,this.props.division,entry)}/>
                                        :
                                        <FontAwesomeIcon style={{cursor:'pointer'}} icon="info" size="2x" onClick={(e) => this.props.setModalSpecialShow(e,true,this.props.specialquestions,this.props.division,entry)}/>
                                    }
                                    </OverlayTrigger>
                                </ListGroup.Item >
                                {/*
                                    <SpecialAnswers
                                        entry = {entry}
                                        division = {this.props.division}
                                        specialquestions = {this.props.specialquestions}
                                        show={this.state.modalShowSpecial}
                                        onHide={(e) => this.props.setModalSpecialShow(e,false)}
                                        answer_list={entry.answer_list}
                                    />
                                */
                                }
                                </span> : <span></span>
                            }
                            <ListGroup.Item >
                            <Form style={{'width':'100px'}}>
                                <Form.Group controlId={`formScore.score-${entry.entry_id }`}>
                                    <Form.Control onChange={(e)=>this.onChangeScore(e)} 
                                        value={this.state.score} 
                                        as="select" 
                                        style={{fontSize:'15px', fontWeight:'800', width:'120px'}}>
                                        {
                                            score.map((item,k) =>
                                            <option key={k} value={item}>{item}</option>

                                            )
                                        }
                                        <option key={11} value={-1}>CONFLICT</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                            </ListGroup.Item >
                            {/*
                                <ListGroup.Item >
                                    <FontAwesomeIcon icon="window-restore" size="2x" onClick={(e) => this.setModalJudgePanelShow(e,true)}/>

                                </ListGroup.Item>
                            */}
                        </ListGroup>
                        {/*
                            <PDFViewer
                                entry = {entry}
                                division = {this.props.division}
                                show={this.state.modalShowPDF}
                                onHide={(e) => this.setModalPDFShow(e,false)}
                                pdflink={this.state.pdflink}
                            />
                            */
                        }
                        {/*
                        <EntryJudgePanel
                            score={this.state.score}
                            onChangeScore={this.onChangeScore}
                            entry = {entry}
                            division = {this.props.division}
                            show={this.state.modalShowJudgePanel}
                            onHide={(e) => this.setModalJudgePanelShow(e,false)}
                            specialquestions = {this.props.specialquestions}
                        />
                        */}
                    </Col>

                </Row>
            </Card>
        )
    }
}

function mapStateToProps(state) {

    const { loader } = state
    const { isFetching, errorMessage, data } = loader
  
    return {
        isFetching,
        errorMessage,
        data,
    }
}

export default connect(mapStateToProps)(Entry)