import React from 'react';
import { Row, Container } from 'react-bootstrap';



class UserFooter extends React.Component{
    constructor(props){
        super(props)
    
    }

    componentDidMount() {
    
    }

    render(){
        const footerStyle ={
            borderTop: '1px solid #555555',
            marginTop: '40px',
            padding: '20px',
            backgroundColor: 'black',
            color: 'white',
            textAlign: 'center',
            fontSize: '14px',

        }
        return(
            <Row style={footerStyle}>
                <Container>
                <p style={{textAlign:'center'}}>
                    © {(new Date().getFullYear())} Commercial Radio Australia. All rights reserved.
                </p>
                </Container>

            </Row>
        )
    }

}

export default UserFooter;