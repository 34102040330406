
import types from './types';


//var apiURL = 'http://localhost:9999/'
// There are three possible states for our login
// process and we need actions for each of them


function requestLogin(creds) {
  return {
    type: types.LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds
  }
}

function receiveLogin(token) {
  return {
    type: types.LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token
  }
}

function loginError(message) {
  return {
    type: types.LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

function requestLogout() {
    return {
      type: types.LOGOUT_REQUEST,
      isFetching: true,
      isAuthenticated: true
    }
  }
  
  function receiveLogout() {
    return {
      type: types.LOGOUT_SUCCESS,
      isFetching: false,
      isAuthenticated: false
    }
  }


  export default{
    requestLogin,
    receiveLogin,
    loginError,
    requestLogout,
    receiveLogout
  }

